import {registerLocaleData} from '@angular/common';
import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import localeId from '@angular/common/locales/id';
import {ErrorHandler, inject, isDevMode, LOCALE_ID, NgModule, provideAppInitializer} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {createErrorHandler, TraceService} from '@sentry/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthService} from './core/backend/auth/auth.service';
import {CoreModule} from './core/core.module';

registerLocaleData(localeId);

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerImmediately:5000',
    }),
  ],
  providers: [
    AuthService,
    {
      provide: LOCALE_ID,
      useValue: 'id',
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler(),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
