import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AuthGuard} from './guard/auth/auth.guard';
import {CanDeactivateGuard} from './guard/can-deactivate/can-deactivate.guard';
import {RoleGuard} from './guard/role/role.guard';
import {InterceptorService} from './interceptor/interceptor.service';

@NgModule({
  providers: [
    AuthGuard,
    RoleGuard,
    CanDeactivateGuard,
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
  ],
})
export class CoreModule {}
